import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Destaca molt sobre les molses pel seu color vermell, encara que només fa 1 cm de diàmetre, primer en forma de copa i posteriorment en forma de disc. La superficie externa és marró rogenca pàl·lida amb nombrosos pèls atapeïts. La interna conté l’himeni, que és de color vermell viu i té el marge pelut, amb pèls llargs marrons. Les espores són globoses amb agullons, de 18-22 micres de diàmetre.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      